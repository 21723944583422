@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary: #F17800;
  --main: #F17800;
  --primary-light: #f17800eb;
  --secondary: #000;

  --main-light: #f17800a1;

  --dark: #55595c;
  --black: #000;
  --white: #fff;
  --light: #eee;
  --yellow: #FFBD13;
  --blue: #4383FF;
  --blue-d-1: #3278FF;
  --grey: #AAA;
  --shadow: 8px 8px 30px rgba(0, 0, 0, .05);
}

html {
  overflow-x: hidden;
}

body {
  font-family: 'Sofia Sans', sans-serif;
}

.bg-main {
  background-color: var(--primary-light);
}

a {
  text-decoration: none;
  transition: all .3s ease;
}

.sliderMain .carousel__back-button,
.sliderMain .carousel__next-button {
  display: none;
}

.btn.btn-main {
  background-image: linear-gradient(260deg, var(--black) 8%, var(--primary) 66%);
}

/* .😕 {
  height: 100%;
} */
/* p.p2,
p.p9,
p.p8 {
  display: none;
} */

.crd .cardImg>img {
  transition: all .75s ease;
}

#newsletter1 {
  filter: none !important;
}

b,
strong {
  font-weight: bolder;
  font-size: 1.35em;
}

.crd:hover .cardImg>img {
  transform: translateY(-4px) scale(1.1);
  filter: drop-shadow(0px 4px 8px #0000001a);
  transition: all .75s ease;
}

.minimalHover {
  cursor: pointer;
  transition: all .5s ease;
}

.minimalHover img {
  filter: grayscale(1);
  transition: all 1s ease;
}

.minimalHover:hover img {
  filter: grayscale(0);
  transition: all 1s ease;
}

.minimalHover:hover {
  transform: translateY(-4px) scale(1.01);
  filter: drop-shadow(0px 4px 8px #0000001a);

}

.crd {
  transition: all .5s ease;
  cursor: pointer;

}

.fs-smm {
  font-size: 12px !important;
}

.blog .card-img-top {
  height: 222px;
  object-fit: cover;
  -webkit-user-drag: none;
}

.crd:hover {
  transform: translateY(-4px) scale(1.01);

}

.sec:nth-child(even) {
  background-color: var(--light);
}

.crd a:hover {
  color: var(--primary-light) !important;
}

.nav-link.active {
  color: var(--primary-light);
}

.nav-link:hover {
  color: var(--primary-light);
}

.btn.btn-dark {
  background: var(--black);
  border: 2px solid var(--light);
}

.btn {
  border-radius: 5px 5px 5px 5px;
  background-color: transparent;
  text-transform: uppercase;
  padding: 12px 20px;
  color: white;
  letter-spacing: 1px;
  font-size: 14px;
  border: 0;
  transition: all .3s ease;
}

.btn.btn-main:hover,
.btn.btn-dark:hover,
.footericons .ico:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px 0px #00000047;
  color: white;
  border-color: var(--white);
}

.para-sm {
  font-size: 15px;
  letter-spacing: 0.2px;
}

.para-xs {
  font-size: 12px;
  letter-spacing: 0.2px;
}

.footericons .ico {
  background: var(--primary);
  width: 28px;
  height: 28px;
  transition: all .3s ease;
  border-radius: 4px;
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderImage {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0)
  }

  50% {
    transform: translatey(-20px)
  }

  to {
    transform: translatey(0)
  }
}

footer .nav-link {
  color: var(--white) !important;
}

.heading {
  font-size: 1.75rem !important;
  font-weight: 500;
  line-height: 1;
  text-align: start;
  text-transform: uppercase;
}

footer .nav-item:after {
  display: none !important;
}

.w-card {
  width: 86px;
}

.sec {
  text-align: center;
}

footer .nav-link:hover {
  color: var(--primary-light);
}

footer {
  background-color: var(--black);
  color: white;
}

.sliderMain {}

.slider {
  /* background-image: url(https://mediachapter.us/wp-content/uploads/2021/10/Asset-2-100.jpg);
  background-size: cover; */
  /* position: absolute; */
  /* display: block; */
  /* width: 100%;
  height: 100%;
  width: 100%;
  height: 75vh;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none; */
}

.sliderMain .horizontalSlider___281Ls,
.sliderMain {
  color: var(--white);
  background: var(--black);
  background: linear-gradient(151deg, rgba(24, 9, 41, 1) 0%, rgba(98, 15, 39, 1) 100%);
}



@media (max-width: 1056px) {
  nav .nav-link {
    font-size: 14px;
  }
}

.skillsCircles {
  /* position: absolute; */
  margin-bottom: 2em;
}

@media (max-width: 954px) {
  .scroll-on .nav-link {
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {

  .login-box {
    min-width: 90% !important;
  }

  .textCards .crdd img {
    width: 100% !important;
    padding: 4px !important;
  }

  .pricing {
    font-size: 70%;
  }

  .bannerSlider,
  .mainSlide {
    height: 30vh !important;
  }

  .btn {
    padding: 6px 14px;
    font-size: 11px;
  }

  .heading {
    font-size: 2em;
    line-height: 1.4;
  }

  .para-sm {
    font-size: 12px;
  }

  .bannerSlider h5,
  .about h5 {
    font-size: 10px;
  }

  .bannerSlider h1,
  .about h1,
  .web-development h1,
  .graphic-design h1 {
    font-size: 18px;
  }
}

.sliderMain .horizontalSlider___281Ls,
.sliderMain {
  height: 100%;
}

.line-height-sm {
  line-height: 1.1
}

.clients .testimonialImg>img {
  width: 200px !important;
  height: 200px;
  border-style: solid;
  border-width: 10px 0px 0px 0px;
  border-color: var(--primary-light);
}


.cls-1 {
  font-size: 50px;
  font-family: Poppins-Bold, Poppins;
  font-weight: 700;
}

.about-cards .cardImg img {
  width: 42px;
}

.cls-1,
.cls-2 {
  fill: var(--primary-light);
}

.cls-2 {
  opacity: 0.15;
}

.navbar-toggler {
  border: 0;
}

.navbar-collapse,
.navbar-collapse.collapse,
.collapsing {
  /* transition: all 50s; */
  transition: all .005s ease 0s;
}

.navbar-collapse.collapse {
  /* padding-block: 2em; */
}

.testimonialImg>img {
  margin-inline: auto;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: unset;
}

.testImg {
  width: 78px !important;
  height: 78px;
  border-radius: 50%;
}

h2 {
  text-align: center;
  padding: 20px;
}

/* Slider */

.customer-logos .slick-slide {
  /* margin: 0px 20px; */
}

.slick-slide img {
  width: 100%;
  padding: 20px;
}

.slick-slide img.testImg {
  padding: 0;
}

@media (max-width: 768px) {
  img[alt="trustpiolet"] {
    width: 65%;
  }

}

@media (max-width: 784px) {

  .navbar-collapse,
  .navbar-collapse.collapse,
  .collapsing {
    transition: all .3s;
    /* transition: all .005s ease 0s; */
  }

}

@media (max-width: 768px) {
  img[alt="trustpiolet"] {
    width: 65%;
  }

}

.sec .h-100.col-sm-6 {
  width: 65%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.image-banner {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 100%;
}


.basic-cards .crd-image img {
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--light);
  -webkit-user-drag: none;
}

.basic-cards .crd-image {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.image-banner {
  height: 450px;
  transition-duration: calc(5000ms*1.2);
}

@media (max-width: 7622px) {
  .image-banner {
    height: 334px;
  }
}

@media (max-width: 480px) {
  /* .image-banner {
    height: 140px;
  } */
}


.contact-img {
  width: 48px;
}


.gallery-wrap img {
  max-width: 100%;
  height: auto;
}

.gallery-wrap,
#gallery {
  overflow: hidden;
}

#filters {
  margin: 1%;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

#filters li {
  float: left;
}


#filters li span {
  display: block;
  padding: 3px 12px;
  font-size: 12px;
  text-decoration: none;
  color: var(--dark);
  cursor: pointer;
  text-transform: uppercase;
  transition: all ease-in-out 0.25s;
  border-radius: 4px;
}

#filters li span.active {
  background: var(--primary);
  color: var(--white);
}

#filters li:hover span.active {
  color: var(--white);
}

#filters li:hover span {
  color: var(--dark);
}


.gallery-item {
  float: left;
  width: 33.333%;
  padding: 10px;
  position: relative;
  z-index: 10;
  display: none;
}

.inside {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  background: #eee;
  border-radius: 12px;
  overflow: hidden;
}

.inside img {
  float: left;
  width: 100%;
  -webkit-user-drag: none;
  transition: all .75s ease;
}

.inside:hover img {
  transform: translateY(-4px) scale(1.1);
}

.inside:hover,
.basic-cards .crd-image:hover {
  transform: translateY(-4px) scale(1.025);
  box-shadow: 0 0.125rem 1rem rgb(0 0 0 / 8%)
}


@media (max-width: 30em) {
  .gallery-item {
    float: none;
    width: 100%;
  }
}



















.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 15px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.start-header.scroll-on {
  box-shadow: 0 5px 18px 0 rgb(155 155 155 / 33%);
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.start-header.scroll-on .navbar-brand img {
  transform: scale(0.9);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.cursorMain {
  position: fixed;
  width: 12px;
  height: 12px;
  background: var(--primary);
  z-index: 2147483647;
  border-radius: 12px;
}

.scroll-on .nav-link {
  font-size: 15px;
}

.navigation-wrap {
  /* position: sticky; */
  top: 0;
  /* position: fixed;
  top: 0;
  left: 0; */
  /* position: relative; */
  width: 100%;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  z-index: 99;
}

.navigation-wrap+div {
  /* transform: translateY(106px) !important;
  margin-bottom: 106px; */
}

@media (max-width:992px) {}

footer .nav-item::before {
  display: none;
}

.navbar {
  padding: 0;
}

.navbar-brand img {
  /* height: 28px;
  width: auto;
  display: block;
  filter: brightness(10%);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; */
}

.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}

.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 18px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid rgb(255, 255, 255);
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: var(--white);
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.nav-link {
  color: #212121 !important;
  font-weight: 500;
  transition: all 200ms linear;
}

.nav-item:hover .nav-link {
  color: var(--primary) !important;
}

.nav-item.active .nav-link {
  color: var(--dark) !important;
}

.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
}

.nav-item:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  content: '';
  background-color: var(--primary);
  opacity: 0;
  transition: all 200ms linear;
}

.nav-item:hover:after {
  bottom: 0;
  opacity: 1;
}

.nav-item.active:hover:after {
  opacity: 0;
}

.nav-item,
.anotherLink {
  position: relative;
  transition: all 200ms linear;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: 40px;
  transform: translateY(-50%);
  z-index: 20;
}

#switch,
#circle {
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

#switch {
  width: 60px;
  height: 8px;
  border: 2px solid var(--primary);
  border-radius: 222px;
  background: var(--black);
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  transform: translate(0);
  transition: all 300ms linear;
  transition-delay: 1900ms;
}

#circle {
  position: absolute;
  top: -11px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: var(--black);
}

.switched {
  border-color: var(--black) !important;
  background: var(--primary) !important;
}

.switched #circle {
  left: 43px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #fff;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: block;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}

.https\:\/\/system\.discounts-space\.com\/ {
  border-radius: 38px;
}

.nav-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
  z-index: 999999999999999999;
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}

.dropdown-toggle::after {
  display: none;

}

.dropdown-item {
  padding: 3px 15px;
  color: #212121;
  border-radius: 2px;
  transition: all 200ms linear;
}

@media (max-width: 7622px) {
  .navbar-nav {
    padding-left: 2em;
  }

  .nav-item:after {
    display: none;
  }

  .nav-item::before {
    position: absolute;
    display: block;
    top: 15px;
    left: -24px;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: var(--black);
    vertical-align: 0;
  }

  .dropdown-toggle::after {
    position: absolute;
    /* display: block; */
    top: 25px;
    right: -16px;
    width: 1px;
    height: 11px;
    content: "";
    border: none;
    /* background-color: var(--black); */
    vertical-align: 0;
    transition: all 200ms linear;
  }

  .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
    opacity: 0;
  }

  .dropdown-menu {
    padding: 0 !important;
    background-color: transparent;
    box-shadow: none;
    transition: all 200ms linear;
  }

  .dropdown-toggle[aria-expanded="true"]+.dropdown-menu {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    visibility: visible;
    opacity: 1;
    max-height: 100%;
  }

}








.nav-link {
  text-transform: uppercase;
}























































.form {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  z-index: 12;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  /* background-image: linear-gradient(260deg, var(--black) 8%, var(--primary) 66%); */
  background-color: var(--primary);
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, var(--black));
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: var(--primary);
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

.form form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 8px;
  transition: 0.3s;
}

.text-main {
  color: var(--primary);
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 8px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: var(--dark);
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.contact-form select option {
  color: var(--black);
}

/* .input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: var(--primary);
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
} */

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: var(--primary);
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, var(--primary), #891134);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid var(--primary);
  border-radius: 50%;
  bottom: -722px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, var(--black), var(--primary));
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
  z-index: 1;
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

.blog .card .card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.tagsList {

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.blog .card .card-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .mainContact {
    background-image: linear-gradient(180deg, var(--white) 50%, var(--primary) 50%) !important;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

.mainContact {
  background-image: linear-gradient(90deg, var(--white) 50%, var(--primary) 50%);
  overflow: hidden;
}

.mainContact>.container-fluid {
  position: relative;
}

.mainContact .big-circle {
  transform: translate(-96%, -35%);
}

.mainContact .form {
  box-shadow: none;
  background-color: transparent;
  overflow: visible;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: var(--primary) !important;
}

@media (max-width: 480px) {
  .contact-info:before {
    display: none;
  }

  .navigation-wrap+div {
    /* transform: translateY(90px) !important;
    margin-bottom: 90px; */
  }

  .navbar-brand img {
    width: 90px;
  }

  .square,
  .big-circle {
    display: none;
  }

  .form form,
  .contact-info {
    padding: 1.7rem 18px;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}

input.input:focus {

  box-shadow: 0 0.125rem 1rem rgb(0 0 0 / 8%) !important;
}

input.input:focus+label,
textarea.input:focus+label {
  transform: translateY(-38px);
  background: transparent;
  backdrop-filter: blur(20px);
}

input.input:not(:placeholder-shown)+label,
textarea.input:not(:placeholder-shown)+label {
  transform: translateY(-38px);
  background: transparent;
  backdrop-filter: blur(20px);
}

.contact_pg input.input::-webkit-input-placeholder,
.contact_pg textarea.input::-webkit-input-placeholder {
  opacity: 0;
}

.contact_pg textarea.input:focus+label {
  transform: translateY(-30px);
}



.login-box {
  background: white;
  padding: 40px;
  min-width: 500px;
  max-width: 700px;
  border-radius: 22px;
  /* border-left: 22px solid var(--primary); */
  box-shadow: 0 0 20px #00000040;
}

.loginMain :is(a, label) {
  color: white !important;
}

.login-box .title {
  font-size: 18px;
  text-align: center;
  padding: 15px 0;
}

.login-box .input-box {
  position: relative;
  margin-bottom: 15px;
}

.login-box .input-box .inputLogin,
.login-box .input-box .input.pass-input {
  border: none;
  color: var(--dark);
  border: 1px solid #cfcfcf;
  background-color: #fff;
  outline: none;
  width: 100%;
  padding: 14px 10px;
  border-radius: 12px;
  margin-top: 20px;
  font-size: 16px;
  /* box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05); */
}

.login-box :is(label) {
  color: var(--dark) !important;
}



.login-box .input-box input:focus~label,
.login-box .input-box input:valid~label {
  top: -8px;
  left: 2px;
  font-size: 12px;
}



.login-box .input-box label {
  position: absolute;
  top: 20px;
  left: 5px;
  padding: 14px 5px;
  display: block;
  pointer-events: none;
  transition: 0.2s;
}

.login-box button[type="submit"] {
  display: block;
  width: 100%;
  border-radius: 12px;
  outline: none;
  border: none;
  /* border-left: 2px solid var(--primary); */
  padding: 15px 0;
  font-size: 16px;
  color: var(--white);
  background: var(--primary);
  margin-top: 30px;
  cursor: pointer;
}


.login-box .auth-action a {
  display: inline-block;
  padding: 15px 0;
  color: #000 !important;
  text-decoration: none;
  font-size: 14px;
}

.nav_list {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.loginMain {

  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */

}

.loginMain form {
  margin-top: 3rem;
}


.autoComplete>div>div {
  display: block !important;
}

.autoComplete>div>div>div {
  background-color: white;
  z-index: 9999;
  /* padding: 12px!important; */
  /* position: relative!important; */
}

.autoComplete>div>div>input {
  padding: 0.8rem;
  width: 100%;
}

.card-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.projects .card .card-body {
  overflow-x: auto;
}


.mdb-color.darken-3 {
  background-color: var(--primary);
}

.project-cards {
  border-radius: 12px;
  cursor: pointer;
  background-color: var(--light);
  transition: all .35s ease;
}

.project-badge {
  transition: all .35s ease;
  transform: translate(-88%, -50%);
}

.project-cards:hover {

  transform: scale(1.025) translateY(-4px);
}

.project-cards:hover .project-badge {

  transform: scale(1.15) translate(-88%, -10%);
}

.sendd {
  position: absolute;
  bottom: 14px;
  right: 20px;
  z-index: 999;
}

.public-DraftEditor-content {
  max-height: 100px;
}

.rdw-editor-wrapper {
  background-color: white;
  position: relative;
}

.bg-light {
  background-color: var(--light);
}

.logo_content.logo i {
  font-size: 28px;
  margin-right: 5px;
}

.logo_content .logo .logo_name {
  font-size: 20px;
  font-weight: 400;
}

.editor-class {
  padding: 12px;
}

.profile_content .profile .profile_details {
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
}



.profile .profile_details img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 12px;
}

.profile .profile_details .name_job {
  margin-left: 10px;
}

.profile .profile_details .name {
  font-size: 15px;
  font-weight: 400;
}

.profile .profile_details .job {
  font-size: 12px;
}

.profile #log_out {
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  min-width: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 12px;
  text-align: center;
}

.profile #log_out:hover {
  background: #fff;
  color: var(--dark);
}


.home_content {
  position: absolute;
  left: 60px;
  width: calc(100%-60px);
}


.home_content .text {
  font-size: 25px;
  font-weight: 500;
  color: var(--dark);
  margin: 12px;
}

.profile-page {
  background: linear-gradient(180deg, var(--primary)50%, var(--white) 50%);
}

.user-profile>img {
  width: 200px;
  height: 200px;
  /* margin-top: 200px; */
}

.user-profile {
  /* position: absolute; */
  background-color: var(--light);
}

.form-control:disabled {
  border: 0;
  background-color: #fff;
}

a:hover {
  cursor: pointer;
}

.mainInvoice {
  background-color: var(--white);
  /* min-width: 45%;
  max-width: 45%; */
  padding: 24px 26px;
  height: 100%;
  /* height: 297mm; */
  margin: 2em;
  /* width: 210mm; */
  /* size: 7in 9.25in;
  margin: 27mm 16mm 27mm 16mm; */
}

.btn-warning.btn {
  background-color: #ffc107;
}

.mainInvoice img {
  width: 140px;
}

.invoiceParent {
  /* height: 200vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.mainInvoice .para-styled {
  margin-block: 8px;
  font-weight: 500;
}

.invoice-table thead {
  background-color: var(--primary);
  color: white;
}

.invoice-table tbody tr:nth-child(even) {
  background-color: var(--light);
}

.mainInvoice {
  user-select: none;
}

th,
td {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}

th:first-child,
td:first-child {
  padding-left: 20px;
  padding-right: 0;
}

.tableTotal {
  border: 2px solid var(--dark);
  padding: 8px 16px;
}

@media (max-width:900px) {
  .mainInvoice :is(p, i, h1, h2, h3, h4, h5, h6, span, a, th, td) {
    font-size: 85% !important;
  }

  .mainInvoice img {
    width: 106px;
  }
}

@media (max-width:700px) {
  .mainInvoice :is(p, i, h1, h2, h3, h4, h5, h6, span, a, th, td) {
    font-size: 70% !important;
  }

  .tableTotal {
    border: 2px solid var(--dark);
    padding: 2px 14px;
  }

  .mainInvoice img {
    width: 108px;
  }

  .mainInvoice .invoicee {
    font-size: 24px !important;
  }

  th:first-child,
  td:first-child {
    padding-left: 18px;
  }

  .mainInvoice {
    padding: 24px 14px;
  }

  th,
  td {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
}

.btn {
  width: auto;
}

@media (max-width:550px) {
  .mainInvoice :is(p, i, h1, h2, h3, h4, h5, h6, span, a, th, td) {
    font-size: 46% !important;
  }

  .mainInvoice img {
    width: 66px;
  }

  .mainInvoice {
    margin: 2em 0em;
  }
}

.italic {
  font-style: italic;
}

.login-box .is-invalid {
  filter: none !important;
  background-color: none;
}

.login-box input:-internal-autofill-selected {
  background: none;
}

@media (max-width:450px) {


  .table-hover :is(td, th) {
    font-size: 11px;
    font-weight: 400;
  }

  .inviz {
    flex-direction: column;
  }

  .invoiceParent {
    width: 88%;
    margin-inline: auto;
  }
}

.about-img.about-img1 img {
  border-radius: 0;
}

.about-img img {
  width: 100%;
  border-radius: 4px;
}

.mb-30 {
  margin-bottom: 30px;
}

.section-tittle {
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-tittle h2 {
    font-size: 30px;
  }
}

.section-tittle h2 {
  font-size: 40px;
  display: block;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 12px;
  position: relative;
}

.about-caption .single-about {
  border-bottom: 1px solid #DBDEE9;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.about-caption .single-about:last-child {
  border-bottom: 0 !important;
}

.about-caption .single-about h5 {
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 16px;
  font-weight: 600;
}

.paypal-button-number-1,
.paypal-button-number-2 {
  display: none !important;
}

.payInvoice {}

.btn.btn-secondary {
  background-color: #6c757d;
}

.input.inp {
  border: 1px solid #bbb;
}

.input.inp::placeholder {
  color: #aaa;
  opacity: 1;
}

div.section.layout_padding.mobile-padding-top_0 {
  text-align: left;
  color: #898989;
  font-size: 15px;
  line-height: normal;
  font-weight: 400;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
  padding-top: 90px;
  padding-bottom: 90px;
  float: left;
  width: 100%;
}

.full {
  float: left;
  width: 100%;
}

.margin_top_50 {
  margin-top: 50px;
}

.text-align_left {
  text-align: left;
}

.process_step h3 {
  font-size: 45px;
  font-weight: 300;
  text-transform: none;
  margin-top: 22px;
}

.process_step h3 span {
  color: var(--primary);
  font-weight: 600;
}

.process_step h3 span {
  color: var(--primary);
  font-weight: 600;
}

.process_step p {
  color: #666;
  font-size: 20px;
  font-weight: 200;
  line-height: 28px;
  margin-top: 0;
}

.process_step_img {
  background: var(--light);
  min-height: 406px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-align_center {
  text-align: center;
}

/* @media (min-width: 992px) and (max-width: 1199px) { */
.process_step_img img {
  width: 265px;
  /* } */

}


@media (max-width: 768px) {
  .process_step {
    min-height: 400px;
  }

  .rowRev {
    flex-direction: column-reverse;
  }

}

.process_step h4 span.sml {
  color: #fff;
  font-size: 25px;
  font-weight: 300;
  overflow: hidden;
}

.margin_top_30 {
  margin-top: 30px;
}

img {
  vertical-align: middle;
  border-style: none;
}

.design_section {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #101010;
  box-sizing: border-box;
  display: block;
  padding-top: 90px;
}

.design_section .design_container .box.b-1 {
  background-color: #24263c;
  color: #ffffff;
  margin-right: -40px;
}

.design_section .design_container .box {
  min-width: 225px;
  min-height: 225px;
  padding: 25px;
  text-align: center;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.design_section .design_container .box h5 {
  font-weight: bold;
  font-size: 22px;
}

.design_section .design_container .box.b-2 {
  background-color: #f4f8fa;
  z-index: 2;
  margin-right: -40px;
}

.design_section .design_container .box.b-3 {
  background-color: var(--primary);
  color: #ffffff;
  z-index: 3;
}

.design_section .design_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout_padding2 {
  padding: 45px 0;
}

.section_hr {
  margin: 10px auto;
  width: 1px;
  height: 175px;
  border: none;
  background-color: #000000;
}



.servicez {
  margin-bottom: 30px;
  position: relative;
}

.servicez .inner-box {
  background: #fff;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 5%);
  padding: 50px 45px 40px;
  position: relative;
}

.servicez .inner-box,
.servicez .inner-box:before {
  border-radius: 22px;
  display: block;
  transition: all .5s ease;
}

.servicez .inner-box .bottom-curve {
  background: #fff;
  bottom: -10px;
  height: 120px;
  opacity: .1;
  position: absolute;
  right: -70px;
  transform: skewX(0deg) scaleX(0);
  transition: all .5s ease;
  width: 120px;
  z-index: 0;
}

.servicez .inner-box .icon-box {
  color: var(--primary);
  display: block;
  font-size: 64px;
  line-height: 1em;
  margin-bottom: 42px;
  position: relative;
  transition: all .5s ease;
}

.servicez .text {
  color: var(--dark);
  display: block;
  padding: 30px 0 0;
  position: relative;
  z-index: 1;
}

.servicez .link-box {
  bottom: 8px;
  height: 30px;
  position: absolute;
  right: 3px;
  width: 30px;
  z-index: 1;
}

.servicez .link-box a {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
}

.servicez .inner-box:hover:before {
  background: var(--dark);
  bottom: -10px;
}

.servicez .inner-box:hover .bottom-curve {
  transform: skewX(-45deg) scaleX(1);
}

.servicez .inner-box:hover .text {
  color: #bdbdbd;
}

.servicez .inner-box:hover h5 a {
  color: #fff;
}

.servicez .inner-box h5 {
  position: relative;
}

.servicez .inner-box:hover .icon-box {
  color: white;
}

.servicez .inner-box,
.servicez .inner-box:before {
  border-radius: 22px;
  display: block;
  transition: all .5s ease;
}

.servicez .inner-box:before {
  bottom: 0;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 4%);
  content: "";
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.textCards .crdd {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 12px;
  border-radius: 12px;
  filter: drop-shadow(rgba(0, 0, 0, 0) 0px 0px 0px);
  transition: all .3s ease;
}

.textCards .crdd img {
  width: 90%;
  transition: all .6s ease;
  padding: 12px;
  filter: drop-shadow(rgb(0, 0, 0) 0px 0px 0px) grayscale(1);
  filter: drop-shadow(-22px 22px 13px rgb(151, 151, 151));
}

.textCards .crdd:hover img {
  transform: translateY(-4px);
  /* filter: drop-shadow(rgb(84, 84, 84) 4px 4px 0px) grayscale(0); */
}

.textCards .crdd:hover {
  transform: translateY(-4px);
  /* background-color: white; */
  /* filter: drop-shadow(rgb(220, 220, 220) 6px 6px 0px); */
}



/* Chat Start  */


#container {
  width: 100%;
  height: 100%;
  background: #eff3f7;
  margin: 0 auto;
  font-size: 0;
  /* border-radius: 5px; */
  overflow: hidden;
}

aside {
  width: 260px;
  height: 100%;
  background-color: var(--black);
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
}

main {
  width: calc(100% - 260px);
  height: 100%;
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
}

aside header {
  padding: 30px 20px;
}

aside input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 50px 0 20px;
  background-color: #3e1e56;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_search.png);
  background-repeat: no-repeat;
  background-position: 170px;
  background-size: 40px;
}

aside input::placeholder {
  color: #fff;
}

aside ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: scroll;
  height: 474px;
}

aside ul::-webkit-scrollbar {
  background-color: #3e1e56;
  width: 4px;
}

aside ul::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

aside ul::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1;
}

aside li {
  cursor: pointer;
  user-select: none;
  padding: 10px 22px;
}

aside li:hover {
  background-color: #3e1e56;
}

aside li img {
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 8px;
}

aside li div {
  display: inline-block;
  vertical-align: top;
  margin-top: 12px;
}

aside li h2 {
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  margin-bottom: 5px;
  padding: 0;
}

aside li h3 {
  font-size: 12px;
  color: #7e818a;
  font-weight: normal;
}

.status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 22px;
}

.green {
  background-color: #13ce00;
}

.orange {
  background-color: #ff725d;
}

.blue {
  background-color: var(--primary);
  margin-right: 0;
  margin-left: 22px;
}

main header {
  background: linear-gradient(145deg, var(--black) 8%, var(--primary) 66%);
  /* height: 64px; */
  color: white;
  display: flex;
  align-items: center;
  padding-block: 18px;
}

main header>* {
  display: inline-block;
  vertical-align: top;
}

main header img:first-child {
  border-radius: 50%;
}

main header img:last-child {
  width: 24px;
  margin-top: 8px;
}

main header div {
  margin-left: 10px;
  margin-right: 145px;
}

main header h2 {
  font-size: 16px;
  margin-bottom: 5px;
  padding: 0;
}

main header h3 {
  font-size: 14px;
  font-weight: normal;
  color: #7e818a;
}

#chat {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: scroll;
  height: 80vh;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  padding-top: 66px;
}

#container .header {
  position: sticky;
  top: 0
}

#chat li {
  padding: 10px 30px;
}

.bg-radiant {
  background-image: linear-gradient(145deg, var(--black) 8%, var(--primary) 66%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

#chat h2,
#chat h3 {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  padding: 0;
}

.btn-custom {
  background-color: var(--primary);
  color: white;
  border: 0;
  padding: 8px 12px;
  display: inline-block;
  border-radius: 12px;
}

.bg-light {
  background-color: #eee;
}

.pricing :is(div.p-3) {
  height: 100%;
}

#chat h3 {
  color: #bbb;
  padding-inline: 12px;
}

.evenn:nth-child(even) {
  background-color: red;
}

#chat .entete {
  margin-bottom: 5px;
}

#chat .message {
  padding: 10px 22px;
  color: #fff;
  line-height: 25px;
  max-width: 90%;
  display: inline-block;
  text-align: left;
  border-radius: 5px;
  position: relative;
}

#chat .message:after {
  content: '';
  position: absolute;
  top: -6px;
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
}

#chat .you .message:after {
  left: 16px;
  background: var(--black);
}

#chat .me .message:after {
  right: 16px;
  background: var(--primary);
}

#chat .me {
  text-align: right;
}

#chat .you .message {
  background-color: var(--black)
}

#chat .me .message {
  background-color: var(--primary);
}

#chat .triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
}

#chat .you .triangle {
  border-color: transparent transparent var(--black) transparent;
  margin-left: 15px;
}

#chat .me .triangle {
  border-color: transparent transparent var(--primary) transparent;
  margin-left: 494px;
}

main footer {
  height: 74px;
  padding: 14px 30px 14px 20px;
  background-image: linear-gradient(145deg, var(--black) 8%, var(--primary) 66%);
}

main footer textarea {
  resize: none;
  border: none;
  display: block;
  width: 100%;
  height: 80px;
  border-radius: 3px;
  padding: 20px;
  font-size: 13px;
  margin-bottom: 13px;
}

main footer input::-webkit-input-placeholder {
  color: white !important;
}

main footer img {
  height: 30px;
  cursor: pointer;
}

main footer a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: #6fbced;
  vertical-align: top;
  margin-left: 333px;
  margin-top: 5px;
  display: inline-block;
}

/* Chat End  */








/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600");
 *, *:before, *:after {
	 margin: 0;
	 padding: 0;
	 border: none;
}
 :before, :after {
	 content: '';
}
 *:focus {
	 outline: 0;
}
 body {
	 text-align: left;
	 background: #000;
	 font-family: 'Open Sans', sans-serif;
	 overflow: hidden;
}
 ul, li {
	 list-style: none;
} */
.comment {
  font-size: 0.8rem;
  color: #999;
}

/* button {
	 display: inline-block;
	 text-align: center;
}
 input[type=checkbox] {
	 display: none;
} */
.options-wrapper {
  position: relative;
  white-space: nowrap;
}

span.options-wrapper {
  display: inline-block;
}

.button-options {
  position: relative;
  display: block;
}

.button-options span {
  margin-right: 0.2rem;
}

.button-options .more {
  float: right;
}

.button-large {
  padding: 0.5rem;
  height: 2rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0 !important;
  padding-inline: 8px !important;
}

.button-large .more {
  display: flex;
  justify-content: center;
  align-items: center;

}

.button-large p {
  margin-bottom: 0;
}

.button-small {
  padding: 0.2rem;
  height: 1.4rem;
}

.button-silver {
  font-weight: bold;
  border: solid 1px #bbb;
  border-radius: 0.2rem;
  background: #eee;
  color: #626262;
}

.button-silver:active {
  border: solid 1px #3f90fe;
}

.button-silver:hover {
  background: #f1f1f1;
  box-shadow: 0 0 1px #626262;
}

.button-grey {
  font-weight: bold;
  background: var(--black);
  color: #bbb;
  border-radius: 0.2rem;
}

.button-grey:hover {
  color: #fff;
  background: #626262;
}

.button-blue {
  background: #3f90fe;
  color: #fff;
  font-weight: bold;
  border-radius: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.right {
  display: inline-block;
  float: right;
}

p.right {
  text-align: right;
}

.hide {
  display: none;
}

.unread {
  font-weight: bold;
}

/* input[type=text], textarea {
	 width: 100%;
	 display: block;
	 -webkit-box-sizing: border-box;
	 -moz-box-sizing: border-box;
	 box-sizing: border-box;
	 box-shadow: inset 0 0 1px #999;
}
 input[type=text]:focus, textarea:focus {
	 box-shadow: inset 0 0 1px #0ff, inset 0 0 4px #999;
} */
textarea {
  /* width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; */
  box-shadow: none;
}

.input-small {
  padding: 0.25rem;
}

.input-medium {
  padding: 0.55rem;
}

.input-large {
  padding: 0.8rem;
}

/* input.input-inline {
	 display: inline-block;
	 max-width: 10rem;
	 margin: 0 0.2rem;
}
 aside {
	 -webkit-user-select: none;
	 -moz-user-select: none;
	 user-select: none;
	 color: #fff;
	 margin-left: 0.6rem;
	 left: 0;
	 top: 0.8rem;
	 height: 100%;
	 width: 10.8rem;
	 z-index: 1;
}
 aside .title {
	 height: 3.4rem;
	 line-height: 3.4rem;
	 padding-left: 0.4rem;
	 border-bottom: solid 1px #626262;
}
 aside li {
	 cursor: pointer;
}
 aside li:hover {
	 background: var(--black);
}
 button.mail-compose {
	 text-transform: uppercase;
	 width: 130px;
	 line-height: 2rem;
	 margin: 0.5rem 0.4rem;
}  */
main {
  /* position: fixed;
	 left: 12.2rem;
	 right: 0;
	 top: 0;
	 bottom: 0;
	 padding-right: 2.2rem;
	 color: #999;
	 min-width: 37rem;
	 height: 100%;
	 z-index: 0; */
}

.new-mail {
  width: 35rem;
  height: 27rem;
  display: inline-block;
  margin-left: 0.4rem;
  vertical-align: bottom;
  position: relative;
}

.new-mail input:focus,
.new-mail textarea:focus {
  box-shadow: none;
}

.new-mail textarea {
  padding: 0.6rem;
  overflow-y: auto;
  position: absolute;
  top: 7.8rem;
  bottom: 2rem;
  left: 0;
  right: 0;
}

.new-mail .footer {
  height: auto !important;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.popup-window {
  background: #fff;
  color: var(--black);
  position: relative;
  z-index: 30;
  text-align: left;
  box-shadow: 0 0 3px #626262;
  pointer-events: auto;
}

.popup-window .title {
  /* height: 1.4rem; */
  padding: 0.6rem 0.8rem;
  background: var(--black);
  font-size: 16px;
  font-weight: 300;
  color: #bbb;
}

input.receiver::-webkit-input-placeholder {
  /* color: red; */
}

.popup-window .menu {
  padding: 0.4rem;
  background: #eee;
  color: #999;
}

.popup-window .menu .button-silver {
  border-radius: 0;
  border: none;
  color: #999;
}

.popup-window .menu .button-silver:hover {
  box-shadow: 0 0 1px #626262;
}

.popup-window.minimized {
  height: 2.6rem;
  width: 17rem;
  overflow: hidden;
}

.popup-window.minimized .min-hide {
  display: none;
}

.popup-window.fullscreen {
  z-index: 0;
  position: fixed;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
}










/* Chat  */



main.mail {
  /* background: linear-gradient(188deg, var(--black) 8%, var(--primary) 66%); */
  background-color: var(--light);
}

ul,
li {
  list-style: none;
}

.searchh {
  display: flex;
}

.comment {
  font-size: 0.8rem;
  color: #999;
}

main button {
  display: inline-block;
  text-align: center;
}

main input[type=checkbox] {
  display: none;
}

.options-wrapper {
  position: relative;
  white-space: nowrap;
}

span.options-wrapper {
  display: inline-block;
}

.button-options {
  position: relative;
  display: block;
}

.button-options span {
  margin-right: 0.2rem;
}

.button-options .more {
  float: right;
}

.button-large {
  padding: 0.5rem;
  height: 2rem;
}

.button-small {
  padding: 0.2rem;
  height: 1.4rem;
}

.button-silver {
  font-weight: bold;
  border: solid 1px #bbb;
  border-radius: 0.2rem;
  background: #eee;
  color: #626262;
}

.button-silver:active {
  border: solid 1px #3f90fe;
}

.button-silver:hover {
  background: #f1f1f1;
  box-shadow: 0 0 1px #626262;
}

.button-grey {
  font-weight: bold;
  background: var(--black);
  color: #bbb;
  border-radius: 0.2rem;
}

.button-grey:hover {
  color: #fff;
  background: #33124d;
}

.button-blue {
  background: #3f90fe;
  color: #fff;
  font-weight: bold;
  border-radius: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.right {
  display: inline-block;
  float: right;
}

p.right {
  text-align: right;
}

.hide {
  display: none;
}

.unread {
  font-weight: bold;
}

main input[type=text] {
  width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: inset 0 0 2px #999;
}

main input[type=text]:focus {
  box-shadow: inset 0 0 1px var(--black), inset 0 0 4px var(--black);
}

.buttonbar {
  position: absolute;
  top: 0;
  width: 100%;
  background: #eee;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-small {
  padding: 0.25rem;
}

.input-medium {
  padding: 0.55rem;
}

.input-large {
  padding: 0.8rem;
}

input.input-inline {
  display: inline-block;
  max-width: 10rem;
  margin: 0 0.2rem;
}

aside {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #fff;
  margin-left: 0.6rem;
  left: 0;
  top: 0.8rem;
  height: 100%;
  width: 10.8rem;
  z-index: 1;
}

aside .title {
  height: 3.4rem;
  line-height: 3.4rem;
  padding-left: 0.4rem;
  border-bottom: solid 1px #626262;
}

aside li {
  cursor: pointer;
}

aside li:hover {
  background: var(--black);
}

button.mail-compose {
  text-transform: uppercase;
  width: 130px;
  line-height: 2rem;
  margin: 0.5rem 0.4rem;
}

.tab-content,
.tabs {
  position: absolute;
  left: 0.6rem;
  width: 10.8rem;
}

.mail-categories,
.tab-content {
  overflow: hidden;
}

.mail-categories:hover,
.tab-content:hover {
  overflow-y: auto;
}

.tabs {
  bottom: 0;
  height: 2rem;
  border-top: solid 1px #626262;
  text-align: center;
}

.tabs input[type='radio'] {
  display: none;
}

.tabs input[type='radio']:checked+label button {
  background: var(--black);
}

.tabs label {
  display: inline-block;
  cursor: pointer;
}

.tabs button,
.tabs button:hover {
  background: transparent;
}

.tabs button {
  display: block;
  width: 2rem;
  border-radius: 0;
  pointer-events: none;
}

.mail-categories {
  height: 13rem;
  position: relative;
}

.mail-categories li {
  padding: 0.1rem 0.4rem;
  position: relative;
}

.mail-categories li.label:after {
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  top: 50%;
  margin-top: -0.4rem;
  right: 0.3rem;
}

.mail-categories li.orange:after {
  background: #ff4500;
}

.mail-categories li.green:after {
  background: #3f3;
}

.mail-categories li.blue:after {
  background: #3f90fe;
}

.mail-categories li.pink:after {
  background: #ffc0cb;
}

.seperator {
  border-top: solid 1px #626262;
  height: 0.8rem;
  cursor: row-resize;
  position: relative;
  z-index: 1;
}

.seperator:after {
  width: 2px;
  height: 2px;
  background: #626262;
  position: absolute;
  left: 50%;
  bottom: 40%;
  box-shadow: 4px 0 #626262, -4px 0 #626262;
  opacity: 0;
}

.seperator:hover:after {
  opacity: 1;
}

.tab-content {
  top: 20.4rem;
  bottom: 2rem;
}

.chatbox {
  padding-left: 0.4rem;
}

.available,
.busy,
.offline,
.talk {
  display: inline-block;
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
  margin-top: 0.3rem;
  margin-right: 0.4rem;
}

.available {
  background: linear-gradient(#3f3, #008000);
}

.busy {
  background: linear-gradient(#daa520, #ff4500);
}

.offline {
  background: linear-gradient(#999, #626262);
}

.talk {
  background: #fff;
}

.status-bar {
  padding-bottom: 0.6rem;
}

.status-bar .status-change {
  position: relative;
  background: transparent;
}

.status-bar .status-change button {
  display: block;
  padding-right: 0.2rem;
  width: 4.4rem;
}

.status-bar .status-change button:hover {
  background: #626262;
}

.status-bar .profile-thumb {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
}

.status-bar .left,
.status-bar .right {
  width: 2.2rem;
  float: right;
}

.status-bar .left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1px;
}

.status-bar .right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.contact-list {
  margin-top: 0.3rem;
  font-size: 0.9rem;
}

.contact-list li {
  line-height: 1.4rem;
  padding-left: 0.3rem;
}

.hideTabs~.mail-categories {
  height: auto;
  bottom: 2rem;
}

.hideTabs~.tab-content {
  display: none;
}

main {
  position: relative;
  /* position: fixed;  */
  /* left: 12.2rem; */
  /* right: 0; */
  /* top: 0; */
  /* bottom: 0; */
  padding-inline: 1.2rem;
  color: #999;
  /* min-width: 37rem; */
  height: 100%;
  z-index: 0;
  width: 100%;
}

.mail-box p {
  margin-bottom: 10px;
}

.smmm p,
.smmm {
  font-size: 12px;
  margin-bottom: 0;
}

.msggg {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
}

main .mail-check+label {
  background: var(--black);
  border: solid 1px #626262;
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
}

main .mail-check+label:before {
  color: var(--white);
  content: '✔';
  opacity: 0;
  display: block;
  margin-top: -0.2rem;
}

main .mail-check+label:hover {
  border: solid 1px #999;
}

main .mail-check:checked+label {
  border: solid 1px #000;
  background: var(--black);
}

main .mail-check:checked+label:before {
  opacity: 1;
}

.mail-search {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.mail-search>button,
.mail-search .input-box>input[type=text] {
  height: 2.2rem;
}

.mail-search>.button-blue {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 4rem;
}

.mail-search>.more {
  background: #fff;
  color: #eee;
  padding: 0.5rem 0;
  width: 1rem;
}

.mail-search>.more:hover {
  color: #999;
}

.mail-search .input-box {
  margin-right: 4rem;
  padding-right: 1rem;
  position: relative;
  pointer-events: none;
}

.mail-search .input-box>input[type=text] {
  box-shadow: none;
  pointer-events: auto;
}

.mail-search .input-box .search-pref {
  position: absolute;
  left: 0;
  right: 0;
  color: #999;
  left: 0;
  padding: 0.8rem;
  box-shadow: 0 0 0 1px #999, 1px 1px 2px #999;
}

.mail-search .input-box .search-pref input[type=checkbox] {
  display: inline;
  margin-right: 0.4rem;
}

.mail-search .input-box .search-pref .options-wrapper button {
  min-width: 6.2rem;
}

.mail-search .input-box .search-pref .button-blue {
  width: 4.8rem;
}

.mail-menu {
  border-top: solid 1px #626262;
  padding: 0.5rem 0 0.5rem 1rem;
  background-color: #8911344a;
  /* margin-left: -1.2rem; */
}

.mail-menu input[type=checkbox].partlyChecked+label:before {
  content: '−';
}

.mail-menu>li {
  display: inline-block;
  margin-left: 0.8rem;
}

.mail-menu>li:first-of-type {
  margin-left: 0;
}

.mail-menu button {
  width: 5rem;
}

.mail-menu li>button {
  display: block;
}

.mail-box {
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 0; */
  /* padding-right: 1rem; */
  /* top: 106px; */
  overflow-y: auto;
  overflow-x: hidden;
}

.mail-box .unread {
  color: var(--black);
  background: var(--white);
}

.mail-box table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: flex;
  /* padding: 1rem; */
  /* margin-top: 1.45em; */
  padding-top: 0;
  flex-direction: column;
}

.mail-box tr {
  line-height: 1rem;
  text-align: left;
  cursor: pointer;
  position: relative;
  border-bottom: solid 1px #62626226;
  padding-left: 0.4rem;
  background-color: var(--white);
  border-left: 2px solid var(--primary);
  padding: 0px;
}

.row:nth-child(even) :is(.pricingtitle, .pricingaction, .pricing) {
  background-color: var(--light);
}

.row :is(.pricingtitle, .pricingaction, .pricing) {
  padding: 0.5rem !important;
}

.pricings .bg-radiant {
  margin-block: .45em;
}

.btn-custom {
  font-size: 10px;
  padding: 8px;
}

.portfolioo>div {
  height: 100%;
}

.CircularProgressbar-text {
  font-family: fantasy;
}

.message p {
  margin: 0;
}

.mail-box tr>a {
  color: var(--dark);
}

.mail-box tr.unread>a {
  color: var(--dark);
}

.mail-box tr:first-of-type {
  border-top: solid 1px #62626226;
}

.mail-box tr:hover .blank-cell:before {
  opacity: 1;
}

.mail-box tr:hover {
  background-color: #ddd;
}

.mail-box tr td {
  /* height: 1rem; */
  line-height: 1rem;
  padding: 8px;
  white-space: nowrap;
}

.mail-box tr label {
  margin-right: 0.4rem;
  display: inline-block;
}

.mail-box tr label:last-of-type {
  margin-right: 0.6rem;
}

.mail-box tr .blank-cell {
  min-width: 0.2rem;
  max-width: 0.2rem;
  padding: 0;
  position: relative;
}

.mail-box tr .blank-cell:before {
  height: 1.4rem;
  width: 8px;
  background: red;
  background: -webkit-radial-gradient(#626262 1px, transparent 1px);
  background: -moz-radial-gradient(#626262 1px, transparent 1px);
  background: radial-gradient(#626262 1px, transparent 1px);
  background-size: 4px 4px;
  position: absolute;
  left: 2px;
  bottom: 0.2rem;
  opacity: 0;
}

.mail-box tr .mail-star+label:before {
  content: '☆';
  color: #626262;
}

.mail-box tr .mail-star:checked+label:before {
  content: '★';
  color: #ffe300;
  text-shadow: 1px 0 #daa520, -1px 0 #daa520, 0 1px #daa520, 0 -1px #daa520;
}

.mail-box tr .mail-important+label {
  width: 0.7rem;
  height: 0.7rem;
  border: solid 1px #626262;
  border-right: none;
}

.mail-box tr .mail-important+label:before {
  width: 62%;
  height: 62%;
  border-top: 1px solid #626262;
  border-right: 1px solid #626262;
  background: inherit;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: translateX(0.41rem) translateY(0.1rem) rotate(35deg) skewY(30deg);
  transform: translateX(0.41rem) translateY(0.1rem) rotate(35deg) skewY(30deg);
  display: block;
}

.mail-box tr .mail-important+label:hover {
  border: solid 1px #999;
  border-right: none;
}

.mail-box tr .mail-important+label:hover:before {
  border-top: 1px solid #999;
  border-right: 1px solid #999;
}

.mail-box tr .mail-important:checked+label {
  background: #ffe300;
  border: solid 1px #daa520;
  border-right: none;
}

.mail-box tr .mail-important:checked+label:before {
  border-top: 1px solid #daa520;
  border-right: 1px solid #daa520;
}

.mail-box span {
  display: inline-block;
}

.mail-box .mail-labels {
  width: 3rem;
}

.mail-box .mail-sender {
  min-width: 9rem;
}

.mail-box .mail-title {
  width: auto;
}

.mail-box .mail-title p {
  margin-bottom: 0;
}

.mail-box .mail-time {
  text-align: right;
  max-width: 4rem;
  /* padding-right: 12px; */
  width: 100%;
}


.mail-unread {
  /* float: right; */
}

.bottom-panel {
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: right;
  z-index: 201;
  padding: 0 0.4rem;
  pointer-events: none;
}

.new-talk {
  vertical-align: bottom;
  display: inline-block;
  width: 17rem;
  margin-left: 0.4rem;
  pointer-events: auto;
}

.new-talk .chat-history {
  height: 7rem;
  margin: 0.4rem;
  position: relative;
}

.new-talk .chat-history .expressions {
  position: absolute;
  right: 0;
  bottom: 0;
  background: transparent;
  color: #3f90fe;
  cursor: pointer;
}

.new-talk .chat-history textarea {
  position: absolute;
  bottom: 0;
  padding: 0.2rem 1.4rem 0.2rem 0.4rem;
}

.new-mail {
  width: 35rem;
  height: 27rem;
  display: inline-block;
  margin-left: 0.4rem;
  vertical-align: bottom;
  position: relative;
}

.new-mail input:focus,
.new-mail textarea:focus {
  box-shadow: none;
}

.new-mail textarea {
  padding: 0.6rem;
  overflow-y: auto;
  position: absolute;
  top: 7.8rem;
  bottom: 2rem;
  left: 0;
  right: 0;
}

.new-mail .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.popup-window {
  transition: all .3s;
  background: #fff;
  color: var(--black);
  position: absolute;
  z-index: 30;
  text-align: left;
  box-shadow: 0 0 3px #626262;
  pointer-events: auto;
  bottom: 0;
  right: 4%;
}

.popup-window .title {
  padding: 14px;
  background: var(--black);
  color: #bbb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.composeScreen :is(button, input, textarea) {
  outline: none;
  border: 0;
}

.popup-window .menu button {
  display: inline-block !important;
}

.popup-window .menu {
  padding: 0.4rem;
  background: #eee;
  color: #999;
}

.popup-window .menu .button-silver {
  border-radius: 0;
  border: none;
  color: #999;
}

.popup-window .menu .button-silver:hover {
  box-shadow: 0 0 1px #626262;
}

.popup-window.minimized {
  height: 2.6rem;
  width: 17rem;
}

.popup-window.minimized .min-hide {
  display: none;
}

.popup-window.fullscreen {
  z-index: 0;
  position: fixed;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
}

.profile-detail {
  position: absolute;
  width: 23rem;
  box-shadow: 1px 1px 3px #999;
  z-index: 300;
}

.profile-detail .content {
  margin-right: 9rem;
  padding: 0.8rem 0 0.8rem 1rem;
}

.profile-detail .profile-img {
  width: 6rem;
  height: 6rem;
  border: solid 5px #fff;
  box-shadow: 1px 1px 3px #bbb;
  margin: 1rem;
  float: right;
}

.profile-detail .menu {
  border-top: solid 1px #bbb;
  clear: both;
}

.profile-detail .menu .button-silver {
  font-weight: normal;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(150, 150, 150, .5);
  z-index: 200;
}

.mail-box .mail-title p {
  max-width: 26rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail-unread {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 5rem;
}

@media (max-width: 1000px) {
  .mail-box .mail-title p {
    max-width: 14rem;
  }

  .mail-unread {
    right: 5rem;
  }

  .mail-box .mail-sender {
    min-width: 7rem;
    max-width: 5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


@media (max-width: 768px) {
  .mail-box .mail-title p {
    width: 192px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .textCards .crdd {
    height: 98px;
  }

  .mail-unread {
    right: 5rem;
  }

  .col-md-8.projects {
    padding: 0 !important;
    margin-left: -26px;
    width: 86%;
  }

  .mail-sender {
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mail-box .mail-sender {
    max-width: 5.6rem;
  }

  .mail-box .mail-sender {
    min-width: 5rem;
  }
}

.mail-box>table>tr {
  position: relative;
  /* border-radius: 8px;
  margin-block: 1px; */
}

.mail-box a {
  padding: 8px;
}

@media (max-width: 620px) {
  .new-mail {
    width: 78%;
  }

  .mail-unread {
    right: 4rem;
  }

  .col-md-8.projects {
    padding: 0 !important;
    margin-left: -6px;
    width: 84%;
  }

  .mail-box tr {
    font-size: 10px;
  }

  .mail-box .mail-sender {
    min-width: 4rem;
  }

  .mail-box .mail-title p {
    width: 164px;
  }



  .mail-box .mail-sender {
    max-width: 4.6rem;
  }
}

@media (max-width: 620px) {
  .mail-box .mail-title p {
    width: 82px;
  }

  .mail-box .mail-sender {
    max-width: 2.6rem;
    min-width: 4rem;
  }
}

/* Chat  */


:where(.css-dev-only-do-not-override-26rdvq).ant-select-single .ant-select-selector {
  border-radius: 0px;
}

.suggestions-list {
  padding: 0;
  margin: 0;
}

.suggestion {
  padding: 8px 14px;
  cursor: pointer;
  transition: all .12s;
}

.suggestion:hover {
  background-color: var(--primary);
  color: white;
}


.completed-projects>div {
  min-height: 100%;
  height: 100%;
}

.projects {
  position: relative;
}

.review {
  bottom: 0;
  background: var(--light);
  padding: 0.65em;
  border-radius: 10px;
  padding-top: 28px;
}




.wrapper {
  background: var(--white);
  padding: 2rem;
  max-width: 576px;
  width: 100%;
  border-radius: .75rem;
  /* box-shadow: var(--shadow); */
  text-align: center;
}

.border-dark {
  border-color: #4a4a4a3d !important;
}

.filter-none.form-control {
  filter: unset !important
}

.wrapper h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: .5rem;
  font-size: 2rem;
  color: var(--yellow);
  margin-bottom: 2rem;
}

.rating .star {
  cursor: pointer;
}

.rating .star.active {
  opacity: 0;
  animation: animate .5s calc(var(--i) * .1s) ease-in-out forwards;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.rating .star:hover {
  transform: scale(1.1);
}

textarea {
  width: 100%;
  background: var(--light);
  padding: 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  resize: none;
  margin-bottom: .5rem;
}

.btn-group {
  display: flex;
  grid-gap: .5rem;
  align-items: center;
}

.btn-group .btn {
  padding: .75rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: .875rem;
  font-weight: 500;
}

.btn-group .btn.submit {
  background: var(--primary);
  color: var(--white);
}

.btn-group .btn.submit:hover {
  background: var(--primary-light);
}

.btn-group .btn.cancel {
  background: var(--white);
  color: var(--dark);
}

.btn-group .btn.cancel:hover {
  background: var(--light);
}

.retingstars {
  cursor: pointer;
}

.review-box {
  background-color: var(--primary);
  color: white;
  padding: 12px;
  border-radius: 8px;
  position: relative
}

.review-box:first-child:before {
  content: '';
  background: var(--primary);
  position: absolute;
  top: -11px;
  right: 50%;
  width: 22px;
  height: 22px;
  transform: rotate(45deg);

}

.retingstars .star {
  transition: all .3s ease;
  display: inline-block
}

.retingstars .star:hover {
  transform: scale(1.1) translateY(-4px);
}












/* Not Found */


.notfound {
  background-color: #edd4d4;
  width: 100vw;
  height: 80vh;
  position: relative;
}

.cont_principal {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cont_error {
  position: absolute;
  width: 100%;
  height: 300px;
  top: 50%;
  margin-top: -150px;
}

.cont_error>h1 {
  font-weight: 400;
  font-size: 7rem;
  color: var(--dark);
  position: relative;
  left: -100%;
  transition: all 0.5s;
}


.cont_error>p {
  font-weight: 300;
  font-size: 1.3rem;
  letter-spacing: 5px;
  color: #ae9292;
  position: relative;
  left: 100%;
  transition: all 0.5s;
  transition-delay: 0.5s;
  -webkit-transition: all 0.5s;
  -webkit-transition-delay: 0.5s;
}

.cont_aura_1 {
  position: absolute;
  width: 300px;
  height: 120%;
  top: 25px;
  right: -340px;
  background-color: var(--primary);
  box-shadow: 0px 0px 60px 20px rgba(222, 100, 100, 0.5);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.cont_aura_2 {
  position: absolute;
  width: 100%;
  height: 300px;
  right: -10%;
  bottom: -301px;
  background-color: var(--primary);
  box-shadow: 0px 0px 60px 10px rgba(214, 95, 95, 0.5), 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 5;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.cont_error_active>.cont_error>h1 {
  left: 0%;
}

.cont_error_active>.cont_error>p {
  left: 0%;
}

.cont_error_active>.cont_aura_2 {
  animation-name: animation_error_2;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform: rotate(-20deg);
}

.cont_error_active>.cont_aura_1 {
  transform: rotate(20deg);
  right: -170px;
  animation-name: animation_error_1;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@-webkit-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}

@-o-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }

}

@-moz-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }

}

@keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}




@-webkit-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

@-o-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

@-moz-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

@keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}



/* Not Found */

/* discount-space-css */
.search-btn {
  background-color: var(--primary);
  width: unset !important;
}

/* .searchbar{
  width: 350px;
} */
.bg-signature {
  background-color: var(--primary);
}

.coupan {
  font-family: inherit;
}

.mobile-nav {
  display: none;
}

@media (max-width: 786px) {
  .nav-css {
    display: none !important;
  }

  .nav-2 {
    display: none !important;
  }

  .mobile-nav {
    display: block;
  }

  .navigation-wrap {
    background-color: var(--primary) !important;
  }

  .mobflag {
    display: block !important;
  }

  .blogo {
    display: none !important;
  }

  .wlogo {
    display: block !important;
  }

  .dropdown-toggle::after {
    display: none !important;
  }
}

.mobflag {
  display: none;
}

.navbar-toggler.notactive .navbar-toggler-icon:after {
  transform: rotate(45deg);
}

.navbar-toggler.notactive .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

.navbar-toggler.notactive .navbar-toggler-icon {
  border: none !important;
}

.searchData {
  padding: 0;
  margin: 0 .5rem;
  position: absolute;
  background: white;
  width: 97%;
  max-height: 70vh;
  overflow-y: auto;
  z-index: 99999;
}

.searchData li {
  padding: .5rem .65rem;
  text-transform: capitalize;
  transition: .3s all;
  user-select: none;
  cursor: default;
}

.searchData li:hover {
  background-color: #f47c2e8f;
  color: white;
}

.overlay.is-active {
  display: block;
  opacity: 1;
  width: 100vw;
  visibility: visible;
  height: 100vh;
}

.overlay {
  left: 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, .6);
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 95% !important;
  }
}

.overlay,
.search-block {
  position: fixed;
  height: 100vh;
  width: 0vw;
  top: 0;
}

.burger-line,
.overlay {
  transition: .3s ease-in-out;
}

@media (min-width:790px) {
  .sidebar.active.db-bar .logo_content .logo {
    opacity: 1;
    pointer-events: none;
  }

  .sidebar.active.db-bar {
    width: 100% !important;
  }

  .sidebar.active.db-bar~.home_content {
    left: 240px;
    width: calc(100% - 240px);
  }

  .sidebar.active.db-bar .profile #log_out {
    left: 88%;
  }

  .sidebar.active.db-bar .profile .profile_details {
    opacity: 1;
    pointer-events: auto;
  }

  .sidebar.active.db-bar .link_names {
    opacity: 1;
    pointer-events: auto;
  }

  .sidebar.active.db-bar ul li .bx-search:hover {
    background: var(--dark);
    color: #fff;
  }

  .sidebar.active.db-bar ul li .tooltip {
    display: none;
  }

  .sidebar.active.db-bar #btn {
    left: 90%;
  }
}

.itemz {
  max-height: 72vh;
  padding: 1em;
  overflow-y: auto;
}

.card-icon-pack .btn {
  color: var(--primary);
}

.sidebar.show {
  left: 0 !important;
  width: 80% !important;
  transition: .4s !important;
}

.sidebar.mob {
  position: fixed;
  width: 0%;
  height: 100vh;
  left: -100%;
  background: #fff;
  transition: .4s;
  z-index: 99999999;
  top: 0;
}

.navigation-wrap {
  position: relative;
  z-index: 999999999;
}

.sidebar.db-bar .logo_content .logo {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 20px;
  opacity: 0;
  pointer-events: none;
  width: 100%;
}


.categ-dropd,
nav.mob.sidebar ul {
  padding: 0;
}

nav.mob ul {
  height: 100%;
  width: 100%;
  list-style: none;
}

a,
nav.mob ul {
  background: 0 0;
}

.list {
  overflow-y: auto;
}

nav.mob ul li {
  border-top: 1px solid #3b9c9d34;
  line-height: 44px;
}

@media (max-width: 1050px) {
  .lh {
    line-height: 23px !important;
    padding: 8px 0;
    background-color: var(--primary);
  }
}

.sidebar.db-bar {
  min-height: 85vh;
  /* position: fixed; */
  top: 0;
  left: 0;
  height: 95%;
  width: 86px;
  background: white;
  /* background: linear-gradient(156deg, var(--black) 8%, var(--primary) 66%); */
  padding: 30px 16px;
  transition: all 0.5s ease;
}

.sidebar.db-bar ul {
  margin-top: 20px;
}

.sidebar.db-bar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  margin-top: 0 5px;
  list-style: none;
  line-height: 50px;

}

.sidebar.db-bar ul li .tooltip {
  position: absolute;
  left: 122px;
  top: 0;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  height: 35px;
  width: 122px;
  background: #fff;
  /* line-height: 35px; */
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}

.editor-class {
  padding: 12px;
}

.sidebar.db-bar ul li:hover .tooltip {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.sidebar.db-bar ul li input {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 12px;
  outline: none;
  border: none;
  background: var(--dark);
  padding-left: 50px;
  font-size: 18px;
  color: #fff;
}

.sidebar.db-bar ul li .bx-search {
  position: absolute;
  z-index: 99;
  color: #fff;
  font-size: 22px;
  transition: all 0.5s ease;
}

.sidebar.db-bar ul li .bx-search:hover {
  background: #fff;
  color: var(--dark);
}


.sidebar.db-bar ul li a {
  color: var(--dark);
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}

.sidebar.db-bar ul li a:hover {
  background: var(--light);
  opacity: .75;
  color: var(--dark);
}

.logo_content .heading {
  color: var(--dark);
}

.mainview .spinner-border {
  width: 12px;
  height: 12px;
}

.sidebar.db-bar ul li a.active {
  background: var(--primary);
  color: var(--white);
}

.sidebar.db-bar ul li i {
  height: 50px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
}

.sidebar.db-bar .link_names {
  opacity: 0;
  pointer-events: none;
}


.sidebar.db-bar .profile_content {
  position: absolute;
  color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
}

.sidebar.db-bar .profile_content .profile {
  position: relative;
  padding: 10px 6px;
  height: 60px;
  background: var(--dark);
}

.burger-line,
.desktop .sign,
.search,
.search-btn {
  background-color: #fff;
}

.mobile-nav .sidebar .list li ul {
  height: unset !important;
}





nav.mob ul {
  height: 100%;
  width: 100%;
  list-style: none;
}

a,
nav.mob ul {
  background: 0 0;
}

.searchData {
  padding: 0;
  margin: 0 0.5rem;
  position: absolute;
  background: white;
  width: 97%;
  max-height: 70vh;
  overflow-y: auto;
  z-index: 99999;
}

.searchData {
  padding: 0;
  margin: 0 0.5rem;
  position: absolute;
  background: white;
  width: 97%;
  max-height: 70vh;
  overflow-y: auto;
  z-index: 99999;
}

.search .fields {
  display: flex;
  width: 100%;
}

.expiry,
.mobile-nav .search .fields input[name=coupon],
.old_price {
  font-size: 12px;
}

.search input {
  font-weight: 600;
  border: none;
  /* border-left: 2px solid #ccc; */
}

.dropdown-toggle .feat-btn,
.search input,
.vd_controls {
  width: 100%;
}

.main-header-search {
  background-color: var(--primary);
}

nav.mob ul li a {
  position: relative;
  color: #212121;
  text-decoration: none;
  font-size: 16px;
  padding-left: 25px;
  font-weight: 400;
  display: block;
  width: 100%;
  border-left: 3px solid transparent;
}

nav.mob ul li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 22px;
  transition: transform .4s;
}

.mobile-nav .sidebar .list li ul {
  height: unset !important;
}





nav.mob ul {
  height: 100%;
  width: 100%;
  list-style: none;
}

a,
nav.mob ul {
  background: 0 0;
}

nav.mob ul ul li {
  line-height: 42px;
  border-top: none;
}

nav.mob ul ul li a {
  font-size: 17px;
  color: #505050;
  padding-left: 55px;
  text-align: left !important;
}

nav.mob ul li a {
  position: relative;
  color: #212121;
  text-decoration: none;
  font-size: 16px;
  padding-left: 25px;
  font-weight: 400;
  display: block;
  width: 100%;
  border-left: 3px solid transparent;
}

.side-nav-buttons .log {
  transition: .3s;
}

.mobile-nav .log {
  color: var(--primary);
  font-weight: 700;
}

.side-nav-buttons .sign {
  background: var(--primary);
  color: #fff !important;
  transition: .3s;
}

.mobile-nav .sign {
  font-weight: 700;
  background-color: #fff;
  color: var(--primary) !important;
  border-radius: 3rem;
}

@media (min-width :786px) {

  .sidebar.mob,
  .overlay {
    display: none !important;
  }

  navbar {
    display: none;
  }

  .mobflag {
    display: none !important;
  }
}

.navbar-toggler {
  position: relative;
  z-index: 99999999999;
}

.nav-css {
  display: none !important;
}

.dropdown-toggle {
  white-space: nowrap;
}

.parentDiv .nav ul {
  list-style: none;
  align-items: center;
}

.desktop .custom-Dropdown {
  top: 50px !important;
}

.custom-Dropdown {
  display: none !important;
  position: absolute !important;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 0 8px rgb(59 88 157 / 30%);
  list-style: none !important;
  padding: 0 20px;
  width: auto;
  margin: 10 !important;
  margin-left: -8px;
  left: 0;
  z-index: 999999999;
  transition: 1s;

}

.flag {
  transition: 1s;
}

.flag:hover .custom-Dropdown {
  display: block !important;
}

.cat-dropdown:hover .custom-Dropdown {
  display: block !important;
}

.desktop .custom-Dropdown li {
  margin: 0.5rem 0;
}

.User-Dropdown>li,
.custom-Dropdown>li {
  padding: 0;
  line-height: 422px;
  border-bottom: 1px solid rgba(215, 215, 215, .17);
}

.custom-Dropdown>li:before,
.mobile-nav .User-Dropdown>li:before {
  margin-top: 11px;
}

.custom-Dropdown>li:before,
.desktop .User-Dropdown>li:before {
  margin-top: -6px;
}

.User-Dropdown>li:before,
.custom-Dropdown>li:before {
  content: '';
  width: 0;
  height: 40px;
  position: absolute;
  background: var(--primary);
  margin-top: 4px;
  border-radius: 0 1px 1px 0;
  left: 0;
  transition: .2s;
}

.parentDiv .nav ul li a {
  margin-right: 21px;
  margin-left: 21px;
  font-size: 12px;
  color: #fff;
  letter-spacing: .2em;
}

.parentDiv .nav ul li a,
.top-links a {
  font-style: normal;
  transition: color .2s ease-in-out;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

.User-Dropdown>li a,
.custom-Dropdown>li a {
  font-size: 13px !important;
  padding: unset !important;
  text-decoration: none !important;
  color: #000 !important;
  transition: .2s ease-out !important;
  margin-right: unset !important;
  margin-left: unset !important;
  font-weight: unset !important;
  font-style: normal !important;
  letter-spacing: unset !important;
  text-transform: unset !important;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

a {
  cursor: pointer;
  border: none;
  text-transform: unset;
  text-decoration: none;
}

a,
nav.mob ul {
  background: 0 0;
}

.burger,
.search input:focus,
.search-input,
a {
  outline: 0;
}


.custom-Dropdown li:hover:before,
.custom-Dropdown>li:hover:before {
  width: 5px;
  border-radius: 30px;
}

nav.mob ul li a span.rotate {
  transform: translateY(-50%) rotate(-180deg);
}

nav.mob ul li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 22px;
  transition: transform .4s;
}

.mobile-nav .sidebar .list li ul {
  height: unset !important;
}

.sidebar.show~.close_btn,
nav.mob ul .categ-show.show4,
nav.mob ul .comm-show.show2,
nav.mob ul .feat-show.show,
nav.mob ul .serv-show.show1,
nav.mob ul .user-show.show3 {
  display: block;
}




nav.mob ul ul {
  position: static;
  display: none;
  /* height: 0px; */
}

nav.mob ul {
  height: 100%;
  width: 100%;
  list-style: none;
}

a,
nav.mob ul {
  background: 0 0;
}

.search-toggle {
  color: white !important;
  width: 14px;
}

.blogo {
  display: block;
}

.wlogo {
  display: none;
}

.top-links a {
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 00em;
}

.parentDiv .nav ul li a,
.top-links a {
  font-style: normal;
  transition: color .2s ease-in-out;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

.country-list.dropdown-toggle::after,
.custom-dropdown-area .dropdown-toggle::after,
.single_footer p,
.single_footer_address ul li {
  color: #fff;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.top-links ul.custom-Dropdown {
  max-height: 60vh;
  min-height: auto;
  overflow-y: auto;
}

.User-Dropdown>li,
.custom-Dropdown>li {
  padding: 0;
  line-height: 422px;
  border-bottom: 1px solid rgba(215, 215, 215, .17);
}

.User-Dropdown>li a,
.custom-Dropdown>li a {
  font-size: 13px !important;
  padding: unset !important;
  text-decoration: none !important;
  color: #000 !important;
  transition: .2s ease-out !important;
  margin-right: unset !important;
  margin-left: unset !important;
  font-weight: unset !important;
  font-style: normal !important;
  letter-spacing: unset !important;
  text-transform: unset !important;
}

.User-area,
.custom-dropdown-area {
  width: auto;
  height: 100%;
  position: relative;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

.b-img {
  width: 100%;
  box-shadow: 0 0 15px #5e5e5e !important;
}

.pata-text {
  font-size: 16px;
}

.heading-md-h {
  font-size: 26px;
  font-weight: 700;
}

.top-cat-btn {
  width: 100% !important;
  height: 100%;
}

.couponimage {
  width: 100%;
}





/* Discount Space  */

.custom-Dropdown>li:before,
.mobile-nav .User-Dropdown>li:before {
  margin-top: 0px;
}

.User-Dropdown>li,
.custom-Dropdown>li {
  padding: 0;
  line-height: 46px;
  border-bottom: 1px solid rgba(215, 215, 215, .17);
}

.carousel-control-prev-icon {
  background-image: url(./Component/assets/DiscountSp/left-arrow.png);
}


.carousel-control-next-icon {
  background-image: url(./Component/assets/DiscountSp/right-arrow.png);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 3rem;
  height: 3rem;
  background-size: 100%;
  opacity: .7;
}

.main-list-card {
  position: relative;
  border-radius: 1.5rem;
}

a:hover {
  opacity: .75;
}

@media (min-width: 768px) {
  .shadow {
    box-shadow: 0 0 15px #ccccccb0 !important;
  }
}

.listViewBanner {
  height: 180px;
}

footer {
  background-color: white;
}

.subscribe {
  display: block;
  position: relative;
  margin-top: 15px;
}

.newsLetterImg {
  width: 50%;
}

img {
  -webkit-user-drag: none;
}

.subscribe__input {
  background-color: #fff;
  border: none;
  border-radius: 40px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 45px;
  letter-spacing: .4px;
  margin: 0;
  padding: 0 65px 0 15px;
  text-align: start;
  text-transform: capitalize;
  width: 100%;
}

@media (min-width: 768px) {
  #toggle_filter {
    display: none;
  }
}

@media (min-width: 768px) {
  .blog-grid-view img {
    height: 430px;
  }
}

.blog-grid-view img {
  object-fit: cover;
}

.listViewBanner img {
  object-fit: contain;
}

.single-blog img {
  width: 100%;
}



.para {
  font-size: 14px;
  font-weight: 500;
}

.subscribe__btn {
  background-color: transparent;
  border-radius: 0 25px 25px 0;
  color: var(--primary);
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 45px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}

.social_profile {
  margin-top: 18px;
}

.contact_social ul,
.social_profile ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.text-muted {
  color: #777 !important;
}

.tag {
  background-color: var(--primary);
  padding: 2px 12px;
  font-size: 12px;
  font-weight: 400;
}

.social_profile ul li a {
  text-align: center;
  border: 1px solid rgba(255, 255, 255, .2);
  transition: .3s;
  margin: 0 5px;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: var(--primary); */
}

.copyright-tag,
.drops button,
.social_profile ul li a {
  text-transform: uppercase;
  background: var(--primary);
}

.bg-signature {
  background-color: var(--primary) !important;
}

.social_profile ul li {
  float: left;
}

.priz {
  color: var(--primary);
}

.main-list-card .widgets {
  display: flex;
  padding: 4px;
  position: absolute;
  bottom: 6%;
  right: 1%;
}

.main-list-card .like-container img {
  padding: 0 !important;
  object-fit: contain;
}

.getCode :is(a, img) {
  height: unset;
}

.getCodeCouponTopLayer {
  cursor: pointer;
}

.main-list-card .like-container {
  height: 30px;
  width: 30px;
  display: flex;
  padding: 4px;
}

.fs-12 {
  font-size: 12px;
}

.getCodeCouponTopLayer:before {
  content: attr(data-coupon-code);
  color: black;
  background-image: repeating-linear-gradient(-45deg, rgba(244, 125, 46, 0.2), rgba(244, 125, 46, 0.2) 1px, transparent 1px, transparent 6px);
  background-size: 8px 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  padding: 0 1rem;
  align-items: center;
  z-index: 8;
}

.getCodeCouponTopLayer img,
.getCodeCouponTopLayerTxt {
  transition: .3s ease;
}

@media only screen and (max-width: 768px) {
  .main-card-foot {
    gap: 12px;
  }

  .main-list-card .widgets {
    position: unset !important;
  }

  .cpnDcImg {
    margin-top: auto !important;
    margin-bottom: auto !important;
    padding: 0 4px;
  }

  .listViewBanner {
    height: 120px;
  }
}

@media (max-width: 992px) {
  .getCodeCouponTopLayer img {
    width: 108px !important;
    height: unset !important;
  }

  .getCode.position-relative {
    width: unset !important;
  }

  .cpnDcImg {
    padding: 0 4px;
  }

  .getCode {
    width: 100% !important;
  }

  .card-txt .heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 16px !important;
  }
}

#heart.red,
.btn.show,
.menu.mobil.is-active a,
.search-cancel::before,
a:hover {
  color: var(--primary);
}

.getCodeCouponTopLayer:hover img {
  transform: translateX(-26px);
}


.anticon {
  vertical-align: 1px !important;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-pagination .ant-pagination-item-active {
  border-color: var(--primary);
}

:where(.css-dev-only-do-not-override-26rdvq).ant-pagination .ant-pagination-item-active:hover {
  border-color: var(--primary);
}

:where(.css-dev-only-do-not-override-26rdvq).ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:where(.css-dev-only-do-not-override-26rdvq).ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--primary);
}




#search_low_price,
#search_high_price {
  padding-block: 0.5rem;
  border-radius: 0.7rem;
  border-color: var(--main-light);
}

.selectMain :is(.selectMain .ant-select-selector, select) {
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  flex: 1;
  padding: 6px !important;
  color: #fff;
  background-color: var(--main-light) !important;
  background-image: none;
  cursor: pointer;
  height: 40px;
}

.category .ant-select-selector {
  height: unset !important;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-select-multiple .ant-select-selection-item-content {
  color: var(--dark);
  font-weight: 500;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-select-single.ant-select-open .ant-select-selection-item {
  color: white !important;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-select-multiple .ant-select-selection-item {
  background: rgb(255 255 255);
  border: 1px solid rgba(5, 5, 5, 0.06);
}

.selectMain .ant-select-selector {
  padding: 0px !important;
}

.selectMain .ant-select-selection-placeholder,
.ant-select-selection-item {
  color: white;
  font-size: 18px;
}

/* Remove IE arrow */
.selectMain select::-ms-expand {
  display: none;
}

.ant-select.ant-select-single.ant-select-show-arrow {
  width: 100% !important;
}

/* Custom Select wrapper */
.selectMain {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: .7em;
  overflow: hidden;
}

.selectMain select {
  padding-block: 0.5rem;
}



/* Arrow */
.selectMain::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em 0.8rem;
  background-color: var(--primary);
  transition: .25s all ease;
  pointer-events: none;
  color: white;
}

/* Transition */
.selectMain:hover::after {
  color: #eee;
}

#dropdownMenuButton1 {
  background-color: var(--main-light) !important;
  padding-block: 0.5rem;
  color: white;
  overflow: hidden;
  border-radius: 0.7rem;
}

button#dropdownMenuButton1::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em 0.8rem;
  background-color: var(--primary);
  transition: .25s all ease;
  pointer-events: none;
  color: white;
  border-width: 0px;
  height: 100%;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}

/* Discount Space  */

#navbarSupportedContent>div>div {
  width: 140%;
}

.card-txt .heading {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}











.slider-wrapper {
  /* width: 760px; */
  margin: auto;
  margin-bottom: 30px !important;
}

.slick-slide {
  text-align: center;
  position: relative;
}

.slick-slide:focus {
  outline: none;
}

.slick-slide-title {
  text-transform: capitalize;
}

.slick-slide-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  /* box-shadow: 0 13px 222px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
    0 -6px 16px -6px hsla(0, 0%, 0%, 0.03); */
}

.slick-slide-label {
  color: #fff;
  padding: 10px;
  position: absolute;
  left: 0px;
  font-size: 1.5em;
  bottom: 0px;
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: #777777;
}

.slick-dots {
  display: none !important;
}

.couponimagediv {
  width: 100%;
}

.fullfill-btn {
  box-shadow: 0 0 15px #ccc !important;
  background-color: white;
  text-transform: capitalize;
}

.couponprice {
  color: var(--primary);
}

.image-upload-wrap {
  background-color: #efefef;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}

.comment_box .comment input,
.comment_box .file input,
.file-upload-input,
.image-upload-wrap {
  height: 100%;
  width: 100%;
}

.file-upload-content {
  /* display: none; */
  text-align: center;
}

.fw-sm-bold {
  font-weight: 600;
}

.a-size-large {
  font-size: 16px;
}

.file-upload-image {
  margin: auto;
  padding: 20px 0;
  height: 170px;
  object-fit: contain;
}

.sliderpdMain img.w-100 {
  height: 300px;
  object-fit: contain;
}


.topSlid .slick-arrow.slick-next {
  right: 0;
  border: none;
  background: url(https://eliteblue.net/Clients/viys/coupon/public/frontend/img/angle-right.png) 0 0/100% no-repeat;
  transition: .3s;
}

.image-container {
  position: relative;
  height: 250px !important;
}

.image-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.card_pp .after {
  opacity: 1 !important;
  background: linear-gradient(0deg, #1c1b1b 6%, #181717 14%, rgba(21, 21, 21, .927608543417367) 21%, rgba(15, 15, 15, .7175245098039216) 36%, rgba(0, 0, 0, 0) 79%);
}

.image-container .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  align-items: end;
  display: flex;
  align-items: flex-end;
  padding: 5px;
}

.image-container .after,
.play img {
  opacity: 0;
  transition: .3s;
}

.topSlid .slick-arrow.slick-next:hover {
  background: url(https://eliteblue.net/Clients/viys/coupon/public/frontend/img/angle-right-hovr.png) 0 0/100% no-repeat var(--primary);
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.main_videos video {
  max-height: 75vh;
  background: var(--light);
}

.w-16 {
  width: 16px;
}

.custom-dropdown-area>.User-avtar>img {
  width: 45px;
  height: 45px;
}

.bg-second {
  background: #f5f7f6 !important;
}

.topSlid .slick-arrow.slick-prev {
  left: 0;
  background: url(https://eliteblue.net/Clients/viys/coupon/public/frontend/img/angle-left.png) 0 0/100% no-repeat;
  border: none;
  transition: .3s;
}

.contact_pg input,
.contact_pg textarea {
  width: 100%;
  border: none;
  height: 60px;
  /* margin-bottom: 15px; */
  padding-left: 25px;
  background-color: #ffffff;
  outline: none;
  color: var(--dark);
  -webkit-box-shadow: 0 0 22px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 0 22px 0 rgb(0 0 0 / 20%);
  border-radius: 12px;
}

.contact_pg textarea {
  border-radius: 18px;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mob .brand,
.play,
.view-detail-cta {
  text-align: center;
}

.card_pp:hover .play img {
  opacity: 1 !important;
  cursor: pointer;
}

.dropdown-toggle .feat-btn,
.search input,
.vd_controls {
  width: 100%;
}

.grid .heading {
  font-size: 22px !important;

}

.grid .main-list-card .widgets {
  bottom: unset !important;
  right: unset !important;
}

.topSlid .slick-arrow.slick-prev:hover {
  background: url(https://eliteblue.net/Clients/viys/coupon/public/frontend/img/angle-left-hovr.png) 0 0/100% no-repeat var(--primary);
}

.topSlid .slick-arrow {
  position: absolute;
  top: 50%;
  margin: -20px 0 0;
  z-index: 10;
  font-size: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .sliderpdMain img.w-100 {
    height: 60vh !important;
    object-fit: contain;
  }
}

.sliderpdMain .sliderpdBot>img {
  height: 108px !important;
  object-fit: contain !important;
}

.file-upload-input,
.image-upload-wrap {
  height: 100%;
  width: 100%;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  outline: 0;
  opacity: 0;
  cursor: pointer;
}

.comment_box {
  width: 100%;
  height: 185px;
}

.share {
  height: 100%;
  background-color: var(--primary);
}

@media (max-width: 786px) {
  .coupon-btn-icon {
    /* width: ; */
  }
}

.light-black {
  color: #777;
}

.showless {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
}





.single-blog img {
  max-height: 100%;
  height: 100% !important;
  width: 100%;
}


@media (max-width: 768px) {

  .login-box .input-box .inputLogin,
  .login-box .input-box .input.pass-input {
    padding: 8px 14px;
  }

  .login-box .input-box label {
    padding: 8px 6px;
  }

  .login-box button[type="submit"] {
    padding: 0px !important;
  }

  .card-icon-pack .btn {
    font-size: 18px;
  }

  .main-list-card .widgets {
    gap: 8px;
    margin-left: 6px;
  }

  .grid .main-list-card .widgets {
    margin-left: 0px;
    margin-bottom: 8px;
  }

}

@media (max-width: 576px) {

  .h1,
  h1 {
    font-size: 18px;
  }

  .grid .heading {
    font-size: 16px !important;
  }

  .main-list-card h4 {
    font-size: 16px !important;
  }

  .main-list-card .para :is(strike, span) {
    font-size: 11px !important;
  }

  .bannerTxt :is(h3),
  .card-txt .para :is(strike, span) {
    font-size: 10px !important;
  }

  .itemsz-row {
    flex-direction: column-reverse;
    margin-block: 8px 14px !important;
  }

  .grid .itemsz-row {
    flex-direction: unset !important;
    margin-block: 8px 14px !important;
  }

  .grid .main-list-card .widgets {
    justify-content: center;
    position: unset !important;
    transform: unset !important;
  }

  .grid .getCodeCouponTopLayer img {
    width: 100% !important;
  }
}

@media (max-width: 420px) {
  .main-list-card>div>div>div.d-flex {

    padding: 0 !important;

  }

  .main-list-card>div>div>div.d-flex a {
    padding: 3px !important;
  }

  .mobLogo {
    width: 128px !important;
  }

  .profile-page {
    background: var(--light);
    padding: 0px !important;
  }

  .profile-page>div {
    box-shadow: unset !important;
  }

  .sidebar.db-bar {
    width: 64px;
    padding: 28px 10px;
  }

  .sidebar.db-bar ul li i {
    min-width: 46px;
  }

  /* .main-list-card>div {
    padding: 8px !important;
  } */

  /* .grid .card-txt>.para {

    flex-direction: column;
    gap: 8px;

  } */




  .grid .card-txt {
    gap: 6px !important;
  }



  .main-list-card .card-txt>p {
    margin-bottom: 0px;
  }

  .heading {
    font-size: calc(1.3rem + .6vw) !important;
  }

  .main-list-card .para {
    font-size: 12px !important;
  }


}


.footer1 {
  background: var(--primary-light);
  color: white;
}

.footer1 .links ul {
  list-style-type: none;
}

.footer1 .links li a {
  color: white;
  transition: color 0.2s;
}

.footer1 .about-company i {
  font-size: 25px;
}

.footer1 .about-company a {
  color: white;
  transition: color 0.2s;
}

.footer1 .location i {
  font-size: 18px;
}

.footer1 .copyright p {
  border-top: 1px solid rgba(255, 255, 255, .1);
}





.top-links a {
  font-size: 14px;
}

.top-links a:hover {
  color: #eee !important;
}

.top-links .custom-Dropdown a:hover {
  color: var(--primary) !important;
}

.top-links svg {
  vertical-align: sub;
}

.thumbnail-slider-wrap .slick-slide img {
  opacity: .45;
}

.thumbnail-slider-wrap .slick-slide.slick-active.slick-current img {
  opacity: 1;
}
















.wrapper {
  padding: 14px;
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
  background: var(--white);
}

.wrapper .icon {
  position: absolute;
  top: 0;
  height: 100%;
  width: 120px;
  display: flex;
  align-items: center;
}

.icon:first-child {
  left: 0;
  display: none;
  background: linear-gradient(90deg, var(--white) 70%, transparent);
}

.icon:last-child {
  right: 0;
  justify-content: flex-end;
  background: linear-gradient(-90deg, var(--white) 70%, transparent);
}

.icon i {
  width: 34px;
  height: 34px;
  cursor: pointer;
  font-size: 1.2rem;
  text-align: center;
  line-height: 34px;
  border-radius: 50%;
}

.icon i:hover {
  background: #eeeeee;
}

.icon:first-child i {
  margin-left: 15px;
}

.icon:last-child i {
  margin-right: 15px;
}

.wrapper .tabs-box {
  display: flex;
  gap: 12px;
  list-style: none;
  margin-bottom: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.tabs-box.dragging {
  scroll-behavior: auto;
  cursor: grab;
}

.tabs-box .tab {
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  background: #eeeeee6b;
  padding: 6px 20px;
  border-radius: 30px;
  border: 1px solid var(--light);
  transition: all .2s;
}

.tabs-box .tab:hover {
  background: #eeeeee;
}

.tabs-box.dragging .tab {
  user-select: none;
  pointer-events: none;
}

.tabs-box .tab.active {
  color: var(--white);
  background: var(--primary);
  border-color: transparent;
}





.tab {
  user-select: none;
}

.shopping-cart {
  /* margin: 20px 0; */
  float: right;
  background: white;
  width: 100%;
  position: absolute;
  border-radius: 3px;
  transition: all .3s ease;
  padding: 20px;
  left: 50%;
  z-index: 9;
  transform: translateX(-50%);
  max-height: 64vh;
  height: 100%;
  overflow-y: auto;
}

.cart-opened {
  transform: translate(-50%, -100px);
  opacity: 0;
  visibility: hidden;
}

.shopping-cart .shopping-cart-header {
  /* border-bottom: 1px solid #e8e8e8; */
  /* padding-bottom: 15px; */
}

.wrapper .ant-select {
  width: 100% !important;
  min-height: 44px;
  margin-bottom: 14px;
}

.wrapper .ant-select-selector {
  min-height: 44px;

}

.promissButton {
  border: none !important;
  box-shadow: none !important;
}

.wrapper .ant-select-selector {
  width: 100%;
  height: 100% !important;
  border-radius: 8px;
}

.wrapper .ant-select-selection-search {
  display: flex;
  justify-content: center;
  align-items: center;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: var(--primary);

}

.ant-select-focused:where(.css-dev-only-do-not-override-26rdvq).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(43, 43, 43, 0.1);
}

.wrapper .ant-select-selector .ant-select-selection-item,
.wrapper .ant-select-selector .ant-select-selection-placeholder {

  line-height: auto !important;
  text-align: start !important;
}

.shopping-cart .shopping-cart-header .shopping-cart-total {
  float: right;
}

.shopping-cart .shopping-cart-items {
  padding-top: 20px;
  padding-left: 0;
}

.shopping-cart .shopping-cart-items li {
  margin-bottom: 18px;
}

.shopping-cart .shopping-cart-items img {
  float: left;
  margin-right: 12px;
}

.shopping-cart .shopping-cart-items .item-name {
  display: block;
  padding-top: 10px;
  font-size: 16px;
}

.shopping-cart .shopping-cart-items .item-price {
  color: var(--primary);
  margin-right: 8px;
}

.shopping-cart .shopping-cart-items .item-quantity {
  color: #abb0be;
}

.shopping-cart:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: var(--primary);
  border-width: 8px;
  margin-left: -8px;
}

.cart-icon {
  color: #515783;
  font-size: 24px;
  margin-right: 22px;
  float: left;
}

.button {
  background: var(--primary);
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  display: block;
  border-radius: 3px;
  font-size: 16px;
  margin: 25px 0 0 0;
}

.button:hover {
  background: var(--primary-light);
  color: var(--white);
}



.popup {
  background-color: var(--white);
  width: 450px;
  padding: 30px;
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  border-radius: 8px;
  text-align: center;
  z-index: 9999999999999;
}

.popup-overlay {
  width: 100vw;
  height: 100vh;
  background-color: #0000005e;
  position: fixed;
  top: 0;
  overflow: hidden;
  backdrop-filter: blur(2px);
  left: 0;
  z-index: 999999999999;
}

.popup button {
  /* display: block;
  */
  /* margin: 0 0 20px auto; */
  background-color: transparent;
  font-size: 30px;
  /* color: #c5c5c5; */
  border: none;
  position: absolute;
  /* outline: none; */
  cursor: pointer;
  right: 16px;
  top: 16px;
}

.popup p {
  font-size: 14px;
  text-align: justify;
  margin: 20px 0;
  line-height: 25px;
}
















.fbButton button {
  margin: 0 !important;
  width: 100% !important;
}

.login-box .auth-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.or {
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #dee3e4;
  position: relative;
  margin: 20px 0;
}

.fbButton>div {
  margin-inline: 0 !important;
}

.login-box .auth-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.or {
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #dee3e4;
  position: relative;
  margin: 38px 0;
}

.or:before {
  content: 'or';
  width: 40px;
  height: 18px;
  position: absolute;
  top: -5px;
  right: calc(50% - 20px);
  background-color: #fff;
  text-align: center;
  line-height: 10px;
  color: #555;
}


.google-btn {
  width: 100%;
  height: 42px;
  background-color: var(--primary);
  border-radius: 12px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
  cursor: pointer;
}

.google-btn .google-icon-wrapper {
  position: absolute;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 12px 2px 2px 12px;
  background-color: var(--white);
}

.google-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}

.google-btn .btn-text {
  /* float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px; */
  margin-left: 54px;
}

.fbButton>div>button {
  background: var(--primary) !important;
  font-size: 16px !important;
  height: 42px !important;
  border-radius: 12px !important;
  padding-inline: 0px !important;
  position: relative;
}

.fbButton>div>button svg {
  position: absolute;
  margin-left: 6px;
  width: 40px;
  height: 40px;
  border-radius: 12px 2px 2px 12px;
  top: 1px;
}

.fbButton>div>button>div>div:nth-child(1) {
  width: 40px;
}

.fbButton>div>button>div>div:nth-child(3) {
  margin-left: 10px;
}

.google-btn:hover {
  /* box-shadow: 0 0 6px #4285f4; */
}

.google-btn:active {
  /* background: #1669f2; */
}

.glButton iframe {
  width: 100% !important;
  margin: auto 0 !important;
}










.tagsList {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}





#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}

#customBtn:hover {
  cursor: pointer;
}

span.label {
  font-family: serif;
  font-weight: normal;
}

span.icon {
  background: url('https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}

span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: 'Roboto', sans-serif;
}